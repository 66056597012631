import './App.css';
import React, { useState, useEffect } from 'react';
import { fetchEntries } from './utilities';

import NavDrawer from "./NavDrawer";
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: { main: '#1EAD96', contrastText: '#ffffff' },
        secondary: { main: '#404040' }, // Purple and green play nicely together.
    },
});

function App(props) {




    return (



            <ThemeProvider theme={theme}>
                <NavDrawer/>
            </ThemeProvider>

    );
}
export default App;
