import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Grid} from "@mui/material";
import LoadingCircle from "./Loading";

export default function DropDownMenuState(props) {
    const [selection, setSelection] = React.useState('');
    const handleChange = (event) => {
        setSelection(event.target.value);
        props.onDropDownChange(event.target.value);
    };

    let sorted_choices;
    if(props.choices && props.choices.length){
        sorted_choices = props.choices;
        sorted_choices.sort((a, b) => {
            if (a.home_state < b.home_state) {
                return -1;
            }
            if (a.home_state > b.home_state) {
                return 1;
            }
            return 0;
        });
    }

    return !(sorted_choices && sorted_choices.length > 0) ? (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <LoadingCircle/>
        </Grid>
        ) : (
            <div>
                <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                    <InputLabel id="demo-simple-select-helper-label">{props.title}</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Age"
                        onChange={handleChange}
                        value={selection}

                    >
                        {sorted_choices.map((state, index) => (
                            <MenuItem key={index} value={state.home_state}>
                                {state.home_state}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>Select a State</FormHelperText>
                </FormControl>
            </div>
        );
}