import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import {Button, Grid, makeStyles, Paper} from "@mui/material";
import InfoCard from "./InfoCard";
import DropDownMenu from "./DropDownMenu";
import {useEffect} from "react";

import DropDownMenuCounty from "./DropDownMenuCounty";
import DropDownMenuState from "./DropDownMenu";
import DropDownMenuCity from "./DropDownMenuCity";
import LoadingCircle from "./Loading";
import {fetchCities, fetchStates} from "./utilities";
import {fetchCitiesNoCounty} from "./utilities";
import {fetchCounties} from "./utilities";
import {fetchEntries} from "./utilities";
import {fetchCounts} from "./utilities";


export default function Home(props) {
    const [states, set_states] =  React.useState([]);
    const [state_selection, set_state_selection] = React.useState('');
    const [counties, set_counties] =  React.useState([]);
    const [county_selection, set_county_selection] =  React.useState('');
    const [cities, set_cities] =  React.useState([]);
    const [city_selection, set_city_selection] =  React.useState('');
    const [isCountsLoading, setIsCountsLoading] = React.useState(false);
    const [isCityCountyLoading, setIsCityCountyLoading] = React.useState(false);
    const [isCityLoading, setIsCityLoading] = React.useState(false);
    const [isStatesLoading, setIsStatesLoading] = React.useState(false);


    const handleStateDropChange = (value) => {
        set_state_selection(value);
        set_county_selection('');
        set_city_selection('');

        console.log(state_selection)
    };

    const handleCountyDropChange = (value) => {
        set_county_selection(value);
        set_city_selection('');
        console.log(county_selection)
    };

    const handleCityDropChange = (value) => {
        set_city_selection(value);
        console.log(city_selection)
    };

    useEffect(() => {
        setIsStatesLoading(true);
        Promise.all([
            fetchStates('get_states', set_states)
        ]).then(() => {
            setIsStatesLoading(false);
        }).catch(() => {
            setIsStatesLoading(false);
        });

    }, []);


    useEffect(() => {
        setIsCityCountyLoading(true);

        if(state_selection){
            Promise.all([
                fetchCounties('get_counties', state_selection, set_counties),
                fetchCitiesNoCounty('get_cities_no_county', state_selection, set_cities)
            ]).then(() => {
                setIsCityCountyLoading(false);
            }).catch(() => {
                setIsCityCountyLoading(false);
            });
        } else {
            setIsCityCountyLoading(false);
        }
    }, [state_selection]);

    useEffect(() => {
        setIsCityLoading(true);
        if(county_selection) {
            Promise.all([
                fetchCities('get_cities', state_selection, county_selection, set_cities)
            ]).then(() => {
                setIsCityLoading(false);
            }).catch(() => {
                setIsCityLoading(false);
            });
        }else {
            setIsCityLoading(false);
        }
    }, [county_selection]);

    let activist_count = "Loading"
    let interested_count = "Loading"
    let total_count = "Loading"

    if (props.activistCount && props.activistCount.length > 0){
        activist_count = props.activistCount[1].count;
    }
    if (props.interestedCount && props.interestedCount.length > 0){
        interested_count = props.interestedCount[1].count;
    }
    if (props.totalCount && props.totalCount.length > 0){
        total_count = props.totalCount[1].count;
    }

    const handleClick = async () => {
        console.log("State: ", state_selection)
        console.log("County: ", county_selection)
        console.log("City: ", city_selection)
        setIsCountsLoading(true);

        try {
            await Promise.all([
                fetchCounts('get_activist_counts', state_selection, county_selection, city_selection, props.setActivistCount),
                fetchCounts('get_interested_counts', state_selection, county_selection, city_selection, props.setInterestedCount),
                fetchCounts('get_total_counts', state_selection, county_selection,city_selection, props.setTotalCount)
            ]);
        } catch (error) {
            console.log(error);
        }

        setIsCountsLoading(false);
    };



    return !(props.activistCount && props.activistCount.length > 0 && props.interestedCount && props.interestedCount.length > 0 &&
        props.totalCount && props.totalCount.length > 0) ? (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <LoadingCircle />
        </Grid>
    ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
                <Grid
                container
                direction="row"
                justifyContent="center"

            >
                    <Paper sx={{ width: '75vh', display: 'flex',justifyContent: 'center', mt: 2 }}>

                    <Grid item xs={12} md={6} alignItems= 'flex-start'>
                    <Grid container direction="column" spacing={3} justifyContent="center">
                        <Grid item xs lg={4}>
                            <InfoCard title={"Activists"} count={activist_count} />
                        </Grid>
                        <Grid item xs lg={4}>
                            <InfoCard title={"Interested"} count={interested_count} />
                        </Grid>
                        <Grid item xs lg={4}>
                            <InfoCard title={"Total"} count={total_count} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction={'column'} xs={12} md={6} alignItems= 'flex-end'>
                                <Grid item lg={4}>
                                    {isStatesLoading ? (
                                        <LoadingCircle />
                                    ) : (
                                        <DropDownMenuState title={"State"} choices={states} onDropDownChange={handleStateDropChange} />
                                    )}
                                </Grid>
                                <Grid item lg={4}>
                                    {isCityCountyLoading ? (
                                        <LoadingCircle />
                                    ) : (
                                        <DropDownMenuCounty title={"County"} choices={counties} onDropDownChange={handleCountyDropChange} />
                                    )}
                                </Grid>
                                <Grid item lg={4}>
                                    {isCityCountyLoading || isCityLoading ? (
                                        <LoadingCircle />
                                    ) : (
                                        <DropDownMenuCity title={"City"} choices={cities} onDropDownChange={handleCityDropChange} />
                                    )}
                                </Grid>
                            </Grid>
                        {/*<Grid item container direction={'column'} xs={12} md={6} alignItems= 'flex-end'>*/}
                        {/*    <Grid item lg={4}>*/}
                        {/*        {isStatesLoading ? (*/}
                        {/*            <LoadingCircle />*/}
                        {/*        ) : (*/}
                        {/*            <DropDownMenuDistrict title={"Districts"} choices={states} onDropDownChange={handleStateDropChange} />*/}
                        {/*        )}*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item lg={4}>*/}
                                {/*{isCityCountyLoading ? (*/}
                                {/*    <LoadingCircle />*/}
                                {/*) : (*/}
                                {/*    <DropDownMenuCounty title={"County"} choices={counties} onDropDownChange={handleCountyDropChange} />*/}
                                {/*)}*/}
                            {/*</Grid>*/}
                            {/*<Grid item lg={4}>*/}
                                {/*{isCityCountyLoading || isCityLoading ? (*/}
                                {/*    <LoadingCircle />*/}
                                {/*) : (*/}
                                {/*    <DropDownMenuCity title={"City"} choices={cities} onDropDownChange={handleCityDropChange} />*/}
                                {/*)}*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                    </Paper>

                </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 2 }}
            >
                {isCountsLoading ? (
                    <LoadingCircle />
                ) : (
                    <Button variant="contained" color="primary" onClick={handleClick}>
                        Get Counts
                    </Button>
                )}
            </Grid>
        </Box>
    );
}
