let api_url
let prod_url = 'http://23.21.25.45:5000/api/';
let dev_url = 'http://localhost:5000/api/'
let test_url = 'https://rsservice.realstrategies.com/api/'

let dev_bool = false;

if(dev_bool){
  api_url = dev_url;
} else{
  api_url = prod_url;
}
export async function fetchEntries(endpoint, setEntity) {
  // console.log(endpoint)
  try{
    const response =  await fetch(api_url + endpoint);
    const jsonData = await(response.json())
    setEntity(jsonData);
  }catch (err){
    console.log(err.message)
  }
}
// export async function fetchCounties(endpoint,state, setEntity) {
//   // console.log(endpoint)
//   try{
//     const response =  await fetch(api_url + endpoint + '/' + state);
//     const jsonData = await(response.json())
//     setEntity(jsonData);
//   }catch (err){
//     console.log(err.message)
//   }
// }

export async function fetchCounties(endpoint,state, setEntity) {
  // console.log(endpoint)
  try{
    const sql_string = encodeURIComponent('SELECT DISTINCT home_county_name FROM caop_consumers WHERE home_state = ' + '\'' + state + '\'' + 'ORDER BY home_county_name')
    const response =  await fetch(test_url + 'postgres?sql=' + sql_string);
    const jsonData = await(response.json())
    let parsedData = JSON.parse(jsonData)
    console.log(parsedData)
    setEntity(parsedData);
  }catch (err){
    console.log(err.message)
  }
}
export async function fetchCities(endpoint,state, county, setEntity) {
  try{

    const sql_string = encodeURIComponent('SELECT DISTINCT home_city FROM caop_consumers WHERE home_state = ' + '\'' + state + '\'' + 'and home_county_name =' +  '\'' + county +  '\'')
    const response =  await fetch(test_url + 'postgres?sql=' + sql_string);
    const jsonData = await(response.json())
    let parsedData = JSON.parse(jsonData)
    console.log(parsedData)
    setEntity(parsedData);
  }catch (err){
    console.log(err.message)
  }
}

export async function fetchStates(endpoint, setEntity) {
  try{

    const sql_string = encodeURIComponent('SELECT DISTINCT home_state FROM caop_consumers')
    const response =  await fetch(test_url + 'postgres?sql=' + sql_string);
    const jsonData = await(response.json())
    let parsedData = JSON.parse(jsonData)
    console.log(parsedData)
    setEntity(parsedData);
  }catch (err){
    console.log(err.message)
  }
}

export async function fetchCitiesNoCounty(endpoint,state, setEntity) {
  try{
    const sql_string = encodeURIComponent('SELECT DISTINCT home_city FROM caop_consumers WHERE home_state =' + '\'' + state + '\'')
    const response =  await fetch(test_url + 'postgres?sql=' + sql_string);
    const jsonData = await(response.json())
    let parsedData = JSON.parse(jsonData)
    setEntity(parsedData);
  }catch (err){
    console.log(err.message)
  }
}


export async function fetchCounts(endpoint,state, county, city, setCount) {
  // console.log(endpoint)
  let sql_string;
  if(endpoint === 'get_activist_counts'){
    if(state === '' && county === '' && city === ''){
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_activist = 1')
    }
    else if(state !== '' && county === '' && city === ''){
      console.log('Hello')
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_activist = 1' + 'AND home_state LIKE' + '\'' + state +  '\'')
    }

    else if(county === '' && city !== ''){
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_activist = 1 AND home_state LIKE' + '\'' + state +  '\'' + 'AND home_city LIKE' + '\'' + city + '\'');
    } else if(county !== '' && city === ''){
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_activist = 1 AND home_state LIKE' + '\'' + state +  '\'' + 'AND home_county_name LIKE' + '\'' + county + '\'');
    }else if(city != 'empty' && county != 'empty') {
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_activist = 1 AND home_state LIKE' + '\'' + state +  '\'' + 'AND home_county_name LIKE' + '\'' + county + '\'' + 'AND home_city LIKE' + '\'' + city + '\'');
    }
  }
  else if(endpoint === 'get_interested_counts'){
    if(state === '' && county === '' && city === ''){
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_interested = 1')
    }
    else if(state !== '' && county === '' && city === ''){
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_interested = 1' + 'AND home_state LIKE' + '\'' + state +  '\'')
    }
    else if(county === '' && city !== ''){
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_interested = 1 AND home_state LIKE' + '\'' + state +  '\'' + 'AND home_city LIKE' + '\'' + city + '\'');
    }
    else if(county !== '' && city === ''){
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_interested = 1 AND home_state LIKE' + '\'' + state +  '\'' + 'AND home_county_name LIKE' + '\'' + county + '\'');
    }
    else if(city != '' && county != '') {
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE is_interested = 1 AND home_state LIKE' + '\'' + state +  '\'' + 'AND home_county_name LIKE' + '\'' + county + '\'' + 'AND home_city LIKE' + '\'' + city + '\'');
    }

  }
  else {
    if (state === '' && county === '' && city === '') {
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers')
    }
    else if(state !== '' && county === '' && city === ''){
      console.log('yo')
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE home_state LIKE' + '\'' + state +  '\'')
    }
    if (county === '' && city !== '') {
      console.log('HiHi')
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE home_state LIKE' + '\'' + state + '\'' + 'AND home_city LIKE' + '\'' + city + '\'');
    }
    if (county !== '' && city === '') {
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE home_state LIKE' + '\'' + state + '\'' + 'AND home_county_name LIKE' + '\'' + county + '\'');
    } else if (city != '' && county != '') {
      sql_string = encodeURIComponent('SELECT COUNT(*) FROM caop_consumers WHERE home_state LIKE' + '\'' + state + '\'' + 'AND home_county_name LIKE' + '\'' + county + '\'' + 'AND home_city LIKE' + '\'' + city + '\'');
    }

  }

  try{
    console.log(sql_string)
    const response =  await fetch(test_url + 'postgres?sql=' + sql_string);
    const jsonData = await(response.json());
    let parsedData = JSON.parse(jsonData)
    setCount(parsedData);
  }catch (err){
    console.log(err.message)
  }
}