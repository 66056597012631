import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {Avatar, Grid} from "@mui/material";
import * as PropTypes from "prop-types";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';


function ArrowDownwardIcon(props) {
    return null;
}

ArrowDownwardIcon.propTypes = {color: PropTypes.string};
export default function InfoCard(props) {
    const theme = useTheme();
    let title = props.title
    console.log(props.count)
    let nf = new Intl.NumberFormat('en-US');
    let count = nf.format(props.count);
    let subtitle = props.subtitle
    return (
        <Card
            sx={{ height: '100%' }}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            {props.title}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {count}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
